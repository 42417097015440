<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title}. ${alertText}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-container fluid class="pt-0">
      <v-form v-model="formValid" ref="form">
        <v-row justify="center">
          <v-col cols="12" class="pb-0">
            <v-card class="mt-6" tile flat>
              <v-card-text class="pt-0 pb-2">
                <v-row class="dense-wrapper">
                  <v-col cols="6">
                    <date-component
                        v-model="header_date" req label="Дата"
                        :placeholder="'Оберіть дату'"
                        :height="56"/>
                  </v-col>
                  <v-col cols="6">
                    <Bank :value="header_bank"
                          @autocompleteChange="bankChangeHeader"
                          color="grey" :height="56"
                          :dense="false"
                          :denseList="true"
                          :required="true"
                          :filled="true"
                          :req="true"
                    />
                  </v-col>
                  <v-col cols="6">
                      <template v-if="legacy">
                          <AUC_FlatSearchLegacy
                                  label="Особовий рахунок"
                                  placeholder="Введіть особовий рахунок"
                                  :flat_id="header_flat_id"
                                  :text_value="header_person_id"
                                  :text_value_key="'person_id'"
                                  :req="true"
                                  filled
                                  :custom_text_field="false"
                                  readonly
                                  text_field
                                  @selectChange="flatChange"
                          />
                      </template>
                     <template v-else>
                         <AUC_FlatSearch
                                 label="Особовий рахунок"
                                 placeholder="Введіть особовий рахунок"
                                 :flat_id="header_flat_id"
                                 :text_value="header_person_id"
                                 :text_value_key="'person_id'"
                                 :req="true"
                                 filled
                                 :custom_text_field="false"
                                 readonly
                                 text_field
                                 @selectChange="flatChange"
                         />
                     </template>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field :label="legacy ? 'Контрагент' : 'Адреса'"
                                  hide-details
                                  readonly
                                  filled
                                  placeholder="Адеса платника"
                                  v-model="header_address"
                                  color="grey"
                    />
                  </v-col>
                  <v-col cols="6" v-if="!legacy">
                    <v-text-field label="Платник"
                                  hide-details
                                  filled
                                  placeholder="П.І.Б платника"
                                  v-model="header_payer"
                                  color="grey"
                                  required
                    />
                  </v-col>
                  <v-col cols="6" v-if="legacy">
                    <ACC_ContractorContract :value="header_contract_id" @autocompleteChange="contractChange"
                                            :person_hash="person_hash"
                                            :contractor_name="header_contract_name"
                                            :class="header_contract_id ? '' : 'req-star'" color="grey"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Коментар"
                                  placeholder="Коментар до платежу"
                                  hide-details
                                  filled
                                  v-model="header_comment"
                                  color="grey"/>
                  </v-col>
                  <v-col cols="6" class="d-flex font-weight-medium">
                    <v-icon class="mr-4" :color="getAccountIcon(header_icon, 'color')">
                      {{ getAccountIcon(header_icon, 'icon') }}
                    </v-icon>
                    <v-select label="Послуга"
                              hide-details
                              required
                              filled
                              :disabled="legacy ? !header_contract_id : false"
                              :items="services_select"
                              placeholder="Оберіть послугу із списку"
                              v-model="header_service_id"
                              color="grey"
                              @change="onServiceChange"
                    />
                  </v-col>
                  <v-col cols="6" class="font-weight-medium">
                    <v-text-field label="Сума"
                                  required
                                  hide-details
                                  filled
                                  placeholder="Введіть суму оплати"
                                  :value="header_sum"
                                  @input="onInput"
                                  :rules="[value => +(value || 0) !== 0 || 'Сума оплати повинна бути більше 0']"
                                  @change="changeSum(0,header_sum)"
                                  color="grey"
                                  v-decimal
                                  :class="!header_sum ? 'error-input' : ''"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="all_services === header_service_id" class="pt-0">
            <v-card tile flat>
              <v-card-text class="pt-2" style="position: relative">
                <div style="font-weight: 400" class="grey--text text--darken-1">Розподіл по послугах</div>
                <div style="height: 1px;" class="grey darken-1"></div>
                <div class="mb-3" style="height: 10px"></div>
                <v-row v-for="(item, index) in services" :key="index" class="dense-wrapper">
                  <v-col cols="6" class="d-flex">
                    <v-icon class="mr-4" :color="getAccountIcon(item.icon, 'color')">
                      {{ getAccountIcon(item.icon, 'icon') }}
                    </v-icon>
                    <v-select label="Послуга"
                              hide-details
                              :items="services_select"
                              required
                              readonly
                              filled
                              placeholder="Оберіть послугу із списку"
                              v-model="item.service_id"
                              color="grey"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Сума"
                                  hide-details
                                  placeholder="Введіть суму оплати"
                                  v-model="item.sum"
                                  v-decimal
                                  filled
                                  color="grey"
                                  :class="error ? 'error-input' : ''"
                                  @change="changeSum(item.id, item.sum)"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="onCityCreate" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {getAccountIcon} from "@/utils/icons";
import {mapGetters} from 'vuex'
import {QUESTION_SHOW} from "@/store/actions/question";
import paymentAPI from "@/utils/axios/payments";
import {ALERT_SHOW} from "@/store/actions/alert";
import {formatDate} from "@/filters";
import {value} from "lodash/seq";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'account_pay_modal_delete'

export default {
  name: "HWP_Modal_AccountPay",
  mixins: [ModalComponentMixin],
  components: {
    Bank: () => import('@/components/autocomplite/Bank'),
    AUC_FlatSearch: () => import("@/components/tableComponent/AUC_FlatSearch.vue"),
    AUC_FlatSearchLegacy: () => import("@/components/tableComponent/AUC_FlatSearchLegacy.vue"),
    ACC_ContractorContract: () => import("@/components/accounting/autocomplite/modal/ACC_ContractorContract")
  },
  data() {
    return {
      documentReady: false,
      legacy: this.item.legacy || false,
      person_hash: this.item.person_hash,
      header_contract_id: this.item.contract_id || null,
      header_contract_name: this.item.contract_name || null,
      header_date: this.item.date || null,
      header_sum: this.item.sum || 0,
      header_bank: this.item.bank || null,
      header_comment: this.item.comment || null,
      header_person_id: this.item.person_id || '',
      header_address: this.item.address || null,
      header_payer: this.item.payer || null,
      header_id: this.item.id || '',
      header_service_id: this.item.service_id || null,
      header_flat_id: this.item.flat_id || null,
      header_balance: this.item.balance || 0,
      header_icon: this.item.icon || '',
      header_month: this.item.month || null,
      row_id: this.item.row_id || null,
      services: this.item.services ? this.item.services : [],
      error: false,
      edited: false
    }
  },
  methods: {
    value,
    contractChange(payload) {
      this.header_contract_id = payload?.value || null
    },
    onInput(e) {
      this.header_sum = +e || 0
    },
    getPayload() {
      let rows = []

      const header = {
        by_bank: false,
        total_sum: this.header_sum,
        total_row: this.services.length ? this.services.length : 1,
        bank: this.header_bank,
        pay_date: this.header_date,
        date: formatDate(Date(), 'YYYY-MM-DD HH:mm:ss'),
        month: this.header_month,
        id: this.header_id
      }
      let sub_services = this.services.map(s => {
        return `[${s.service_id},${s.sum || 0},${s.commission || 0}]`
      })

      if (!this.edited) {
        rows.push(
            {
              header_id: 1,
              flat_id: this.header_flat_id,
              pay_date: this.header_date,
              sum: this.header_sum,
              bank: this.header_bank,
              payer: this.header_payer,
              service_id: this.header_service_id,
              is_group: false,
              edited: this.edited || false,
              sub_services: sub_services.length ? sub_services : null,
              contract_id: this.header_contract_id || null,
              services: [],
              id: this.row_id,
            }
        )
      } else {
        rows.push(
            {
              header_id: 1,
              flat_id: this.header_flat_id,
              pay_date: this.header_date,
              sum: this.header_sum,
              bank: this.header_bank,
              payer: this.header_payer,
              service_id: this.header_service_id,
              is_group: false,
              edited: this.edited || false,
              sub_services: sub_services.length ? sub_services : null,
              contract_id: this.header_contract_id || null,
              services: this.services.map(s => {
                return {service_id: s.service_id, sum: s.sum}
              }),
              id: this.row_id,
            }
        )
      }

      return {
        header,
        rows,
        for_delete: []
      }

    },
    getPayloadForRemove() {
      return {
        header_id: this.header_id,
        row_id: this.row_id,
      }

    },
    getAccountIcon,
    closeModal(payload = null) {
      this.$emit('closeModal', payload)
      this.legacy = this.item.legacy || false
      this.person_hash = this.item.person_hash || null
      this.header_contract_id = this.item.contract_id || null
      this.header_contract_name = this.item.contract_name || null
      this.header_date = this.item.date || null
      this.header_sum = this.item.sum || 0
      this.header_bank = this.item.bank || null
      this.header_comment = this.item.comment || null
      this.header_person_id = this.item.person_id || ''
      this.header_address = this.item.address || null
      this.header_payer = this.item.payer || null
      this.header_id = this.item.id || null
      this.header_service_id = this.item.service_id || null
      this.header_flat_id = this.item.flat_id || null
      this.header_balance = this.item.balance || 0
      this.header_icon = this.item.icon || ''
      this.header_month = this.item.month || null
      this.services = this.item.services ? this.item.services : []
      this.error = false
      this.row_id = this.item.row_id || null
      this.edited = false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення оплати`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    onCityCreate() {
      if (this.legacy) {
        this.createCityLegacy()
      } else {
        this.createCity()
      }
    },
    createCity() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.$store.commit(ALERT_SHOW, {
          text: 'Усі обов\'язкові поля мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = this.getPayload()

      if (this.isNew) {
        paymentAPI.create_row(payload)
            .then(response => response.data)
            .then(data => {
              if (data) {
                this.closeModal({operation: 'fetch'})
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })

      } else {
        paymentAPI.update_row(payload)
            .then(response => response.data)
            .then(data => {
              if (data) {
                this.closeModal({operation: 'fetch'})
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })

      }

    },
    createCityLegacy() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.$store.commit(ALERT_SHOW, {
          text: 'Усі обов\'язкові поля мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = this.getPayload()

      if (this.isNew) {
        paymentAPI.create_row_legacy(payload)
            .then(response => response.data)
            .then(data => {
              if (data) {
                this.closeModal({operation: 'fetch'})
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })

      } else {
        paymentAPI.update_row_legacy(payload)
            .then(response => response.data)
            .then(data => {
              if (data) {
                this.closeModal({operation: 'fetch'})
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })

      }

    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            immediate: true,
            handler(payload) {
              const all_service_id = (this.settings.all_services || {}).value || 0

              this.person_hash = payload.person_hash
              this.legacy = payload.legacy || false
              this.header_contract_id = payload.contract_id
              this.header_contract_name = payload.contract_name
              this.header_date = payload.date || null
              this.header_sum = payload.sum || 0
              this.header_bank = payload.bank || null
              this.header_comment = payload.comment || null
              this.header_person_id = payload.person_id || ''
              this.header_address = payload.address || null
              this.header_payer = payload.payer || null
              this.header_id = payload.id || null
              this.header_service_id = payload.service_id || null
              this.header_flat_id = payload.flat_id || null
              this.header_balance = payload.balance || 0
              this.header_icon = payload.icon || ''
              this.header_month = payload.month || null
              this.services = all_service_id
                  ? payload.services
                      ? payload.services : []
                  : []
              this.error = false
              this.row_id = this.item.row_id || null
              this.edited = false
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  const payload = this.getPayloadForRemove()
                  paymentAPI.remove_row(payload)
                      .then(ok => {
                        if (ok) {
                          this.closeModal({operation: 'fetch'})
                        }
                      })
                      .catch(err => {
                        const error = err.response.data.detail;
                        this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                      })
                }
              }
            }
          }
      )
    },
    saveDocument() {
      console.log('save document')
    },
    bankChangeHeader(payload) {
      this.header_bank = payload.value
    },
    flatChange(payload) {
      this.header_person_id = payload.person_id
      this.header_flat_id = payload.value
      if (this.legacy) {
          this.header_address = payload.contractor_name
          this.header_payer = payload.contractor_name
      } else {
          this.header_address = payload.address
          this.header_payer = payload.flat_owner
      }

      this.changeService(this.header_service_id)
    },
    onServiceChange(service_id) {
      if (this.legacy) {
        this.changeServiceLegacy(service_id)
      } else {
        this.changeService(service_id)
      }
    },
    changeService(service_id) {
      const service_row = this.services_select.find(item => item.value === service_id)
      const all_service_id = (this.settings.all_services || {}).value || 0
      if (service_row) {
        this.header_icon = service_row.type
      }

      if (this.services.length && service_id === all_service_id) {
        this.splitSum(this.header_sum)
      } else if (this.services.length && service_id !== all_service_id) {
        this.services = []
      } else if (!this.services.length && service_id === all_service_id) {
        const header_id = this.isNew ? -1 : this.header_id
        this.get_services_for_split({header_id: header_id, flat_id: this.header_flat_id})
            .then(response => {
              this.services = response
              this.splitSum(this.header_sum)
            })
            .catch(() => {
              this.services = [
                {
                  id: 0,
                  pay_date: this.header_date,
                  service_id: 0,
                  bank: this.header_bank,
                  sum: 0,
                  balance: 0,
                  icon: ''
                }
              ]
            })
            .finally(() => {
              this.splitSum(this.header_sum)
            })
      }
    },
    changeServiceLegacy(service_id) {
      const service_row = this.services_select.find(item => item.value === service_id)
      const all_service_id = (this.settings.all_services || {}).value || 0
      if (service_row) {
        this.header_icon = service_row.type
      }

      if (this.services.length && service_id === all_service_id) {
        this.splitSum(this.header_sum)
      } else if (this.services.length && service_id !== all_service_id) {
        this.services = []
      } else if (!this.services.length && service_id === all_service_id) {
        const header_id = this.isNew ? -1 : this.header_id
        this.get_services_for_split_legacy({
          header_id: header_id,
          flat_id: this.header_flat_id,
          contract_id: this.header_contract_id
        })
            .then(response => {
              this.services = response
              this.splitSum(this.header_sum)
            })
            .catch(() => {
              this.services = [
                {
                  id: 0,
                  pay_date: this.header_date,
                  service_id: 0,
                  bank: this.header_bank,
                  sum: 0,
                  balance: 0,
                  icon: ''
                }
              ]
            })
            .finally(() => {
              this.splitSum(this.header_sum)
            })
      }
    },
    doSplit(iterable, sum, operation = 'positive') {
      let sumForSplit = sum + 0
      let total = 0
      let assign = operation === 'negative' ? -1 : 1
      let add = operation === 'equal' ? 100 : 0

      const func = {
        ['positive']: item => item.balance > 0,
        ['negative']: item => item.balance < 0,
        ['equal']: item => item.balance === 0
      }

      iterable.forEach(item => item.sum = 0)

      const filter_array = iterable.filter(func[operation]).map(item => {
        total += item.balance * assign + add
        return {id: item.id, balance: item.balance * assign + add, result: 0}
      })

      if (operation === 'negative') {
        const reversed = filter_array.sort((a, b) => a.balance - b.balance).reverse()

        reversed.forEach((item, index) => {
          filter_array[index].id = item.id
        })
      }

      filter_array.forEach(item => {
        const k = sumForSplit / total
        item.result = +(item.balance * k).toFixed(2) || 0

        sumForSplit -= item.result
        total -= item.balance

        const i = iterable.find(it => it.id === item.id)
        if (i) {
          i.sum = item.result
        }
      })

      sumForSplit = +(sumForSplit.toFixed(2))
      return sumForSplit

    },
    splitSum(sum) {
      let new_sum = sum + 0
      let sumForSplit = this.doSplit(this.services, new_sum, 'positive')
      if (sumForSplit > 0) {
        sumForSplit = this.doSplit(this.services, new_sum, 'negative')
      }
      if (sumForSplit > 0) {
        sumForSplit = this.doSplit(this.services, new_sum, 'equal')
      }
      this.checkSumForSubItems()
    },
    get_services_for_split(payload) {
      return new Promise((resolve, reject) => {
        paymentAPI.get_services_for_sub_item(payload)
            .then(response => response.data)
            .then(data => {
              let idx = 0
              data.forEach(item => {
                idx += 1
                if (item.id === 0) {
                  item.pay_date = this.header_date
                  item.bank = this.header_bank
                  item.id = idx
                }
              })
              resolve(data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
              reject(false)
            })
      })
    },
    get_services_for_split_legacy(payload) {
      return new Promise((resolve, reject) => {
        paymentAPI.get_services_for_sub_item_legacy(payload)
            .then(response => response.data)
            .then(data => {
              let idx = 0
              data.forEach(item => {
                idx += 1
                if (item.id === 0) {
                  item.pay_date = this.header_date
                  item.bank = this.header_bank
                  item.id = idx
                }
              })
              resolve(data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
              reject(false)
            })
      })
    },
    changeSum(id, sum) {
      this.$nextTick(() => {
        this.error = false
        if (!id) {
          this.splitSum(sum)
        } else {
          this.edited = true
          this.checkSumForSubItems()
        }
      })
    },
    checkSumForSubItems() {
      const child_sum = this.services.reduce((total, el) => {
        return +el.sum + total
      }, 0)
      this.error = this.header_sum !== (+child_sum.toFixed(2));
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      services_select: 'getServicesSelect',
      settings: 'getGeneralSettings',
    }),
    all_services() {
      return (this.settings.all_services || {}).value || 0
    },
    alertText() {
      return this.settings?.split_type_by_manual?.value || '' === 'all_service'
          ? 'Автоматичний поділ оплат'
          : 'Не розділяти автоматично'
    }
  }
}
</script>

<style scoped lang="scss">
.pay-details {
  left: -1px;
  color: white;
  z-index: 1;
  width: 50%;
  font-weight: 400;
  font-size: 0.8rem;
  text-transform: uppercase;
  display: block;
  background: #2d2d2de3;
  padding: 0 14px;
  /* border-radius: 5px; */
  position: absolute;
  top: -13px;
}

.error-input {
  &:deep(.v-text-field__slot) {
    label {
      color: var(--v-error-base) !important;
    }
  }

  &:deep(.v-input__slot:before) {
    border-color: var(--v-error-base) !important;
  }
}
</style>